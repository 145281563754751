import { Formik } from 'formik'
import React, { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { VehicleFormValues } from './VehicleInsuranceForm.interface'
import { NonVehicleSchema, VehicleSchema } from './VehicleInsuranceForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { FileUploadInput } from 'src/components/shared/FileUploadInput'
import { colors } from 'src/theme'
import { FormikSelect } from 'src/components/shared/form/formik/FormikSelect'
import { nonInsuredVehicles, vehicleFormTypes, VehicleType } from 'src/constants/vehicle'
import { UploadedFile } from 'src/components/shared/UploadedFile'
import { ShipmentMajor } from '@shopify/polaris-icons'
import { InfoContainer } from 'src/components/shared/InfoContainer'
import { CommonModal } from 'src/components/shared/CommonModal'
import { OnboardingUploadVehicleInsurance } from 'src/constants/actionTypes'
import { OnboardingStepIndex } from '../OnboardingStepIndex'
import { OnboardingStepType } from 'src/constants/onboarding'
import { getCurrentOnboardingStep } from 'src/selectors/onboarding'

const initialValues: VehicleFormValues = {
  type: VehicleType.Sedan,
  carMake: '',
  carModel: '',
  doorsCount: '',
  year: '',
}

const YEAR_MASK = [/\d/, /\d/, /\d/, /\d/]
const DORS_MASK = [/\d/, /\d/]

const ACCESS_TYPES = ['image/jpeg', 'image/png', 'application/pdf']

export const VehicleInsuranceForm = () => {
  const dispatch = useDispatch()

  const currentStep = useSelector(getCurrentOnboardingStep)

  const formikRef = useRef<any>()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [vehicleType, setVehicleType] = useState<VehicleType>(VehicleType.Sedan)
  const [insuranceFile, setInsuranceFile] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (values: VehicleFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(
        dispatch,
        OnboardingUploadVehicleInsurance.request({
          ...values,
          file: insuranceFile,
          stepId: currentStep?.id,
        }),
      )
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUploadInsurance = useCallback(async (file: File) => {
    setInsuranceFile(file)
  }, [])

  const handleRemoveFile = useCallback(() => {
    setInsuranceFile(null)
  }, [])

  const openModal = useCallback(() => {
    setShowModal(true)
  }, [])

  const closeModal = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      validationSchema={nonInsuredVehicles.includes(vehicleType) ? NonVehicleSchema : VehicleSchema}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit, handleChange }) => (
        <div css={tw`h-full w-full flex flex-col justify-between`}>
          <div>
            <div
              css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mt-2 mb-4`}
            >
              <ShipmentMajor width={20} />
            </div>
            <OnboardingStepIndex type={OnboardingStepType.VehicleInsurance} />
            <Typography css={tw`my-2`} variant="h2">
              Vehicle and insurance
            </Typography>
            <Typography css={tw`mb-4 w-3/4`} color={colors.SHADES_GREY_400}>
              Input your vehicle details and upload your insurance card.
            </Typography>
            <div css={tw`mb-4`}>
              <InfoContainer onClick={openModal}>
                Tap to access additional information about the insurance document
              </InfoContainer>
            </div>
            <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
              Enter vehicle details
            </Typography>
            <div css={tw`bg-white p-4 rounded-xl mt-2`}>
              <div css={tw`w-full mb-4`}>
                <FormikSelect
                  name="type"
                  label="Vehicle type"
                  placeholder="Select vehicle type"
                  options={vehicleFormTypes}
                  onChange={(e) => {
                    setVehicleType(e?.target?.value as VehicleType)
                    handleChange(e)
                  }}
                />
              </div>
              {!nonInsuredVehicles.includes(vehicleType) && (
                <>
                  <FormikTextField
                    name="year"
                    css={tw`w-full mb-4`}
                    label="Vehicle year"
                    placeholder="Enter year of production"
                    mask={YEAR_MASK}
                  />
                  <FormikTextField
                    name="carMake"
                    css={tw`w-full mb-4`}
                    label="Make"
                    placeholder="Enter vehicle make E.g. Toyota"
                  />
                  <FormikTextField
                    name="carModel"
                    css={tw`w-full mb-4`}
                    label="Model"
                    placeholder="Enter vehicle model E.g. Tacoma XL"
                  />
                  <FormikTextField
                    name="doorsCount"
                    css={tw`w-full`}
                    label="Number of doors"
                    mask={DORS_MASK}
                    placeholder="Enter number of doors"
                  />
                </>
              )}
            </div>
            <div css={tw`mt-6`}>
              <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
                {nonInsuredVehicles.includes(vehicleType)
                  ? 'Upload Photo of the vehicle'
                  : 'Upload insurance ID card'}
              </Typography>
              <div css={tw`bg-white p-4 rounded-xl mt-2`}>
                {insuranceFile ? (
                  <UploadedFile file={insuranceFile} onRemove={handleRemoveFile} />
                ) : (
                  <FileUploadInput
                    // 10 MB
                    fileSizeLimit={10000000}
                    acceptTypes={ACCESS_TYPES}
                    onSelect={handleUploadInsurance}
                  />
                )}
              </div>
            </div>
          </div>
          <FormErrorMessage css={tw`mb-4`} error={error} />
          <LoadingButton
            css={tw`w-full mt-6`}
            variant="contained"
            loading={isLoading}
            disabled={!insuranceFile}
            onClick={() => handleSubmit()}
          >
            <span>Submit and continue</span>
          </LoadingButton>
          <CommonModal
            isOpen={showModal}
            image={require('../../../assets/images/vehicle-insurance-example.png')}
            onClose={closeModal}
          />
        </div>
      )}
    </Formik>
  )
}
