import React, { memo, useMemo } from 'react'
import { Modal, Typography } from '@mui/material'
import tw from 'twin.macro'
import {
  // ChatMajor,
  ChevronRightMinor,
  CircleRightMajor,
  MobileCancelMajor,
} from '@shopify/polaris-icons'
import Logo from 'src/assets/images/gigsafe-logo-black.svg'
import { Button } from '../shared/Button'
import RecollectIcon from 'src/assets/icons/recollect.svg'

interface Props {
  isOpen: boolean
  onClose: () => void
  onLogout: () => void
  onGetHelp: () => void
  onSwitchPartner?: () => void
}

const MobileMenuComponent = ({ isOpen, onClose, onLogout, onSwitchPartner }: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`w-full h-full`}
    >
      <div css={tw`w-full h-full flex flex-col`}>
        <div css={tw`flex px-4 py-3 justify-between items-center bg-white`}>
          <img src={Logo} />
          <Button
            leftIcon={<MobileCancelMajor css={tw`mx-1`} width={20} height={20} />}
            onClick={onClose}
            color="grey"
            size="medium"
          />
        </div>
        <div css={tw`bg-[#F6F6F6] p-4 h-fit flex-1 flex flex-col`}>
          <div css={tw`bg-white rounded-2xl overflow-hidden`}>
            {/* <div
              css={tw`p-4 flex items-center justify-between hover:bg-[#EDEDED] active:bg-[#DAD9E0]`}
              onClick={onGetHelp}
            >
              <div css={tw`flex items-center`}>
                <div
                  css={tw`h-11 w-11 rounded-[22px] items-center justify-center flex bg-[#F6F6F6]`}
                >
                  <ChatMajor width={20} height={20} />
                </div>
                <Typography fontWeight={600} css={tw`ml-4`}>
                  Get help
                </Typography>
              </div>
              <ChevronRightMinor fill="#B4AFC0" width={20} height={20} />
            </div> */}
            {onSwitchPartner ? (
              <div
                css={tw`p-4 flex items-center justify-between hover:bg-[#EDEDED] active:bg-[#DAD9E0]`}
                onClick={onSwitchPartner}
              >
                <div css={tw`flex items-center`}>
                  <div
                    css={tw`h-11 w-11 rounded-[22px] items-center justify-center flex bg-[#F6F6F6]`}
                  >
                    <img src={RecollectIcon} />
                  </div>
                  <Typography fontWeight={600} css={tw`ml-4`}>
                    Switch partner
                  </Typography>
                </div>
                <ChevronRightMinor fill="#B4AFC0" width={20} height={20} />
              </div>
            ) : null}
            <div
              css={tw`p-4 flex items-center justify-between hover:bg-[#EDEDED] active:bg-[#DAD9E0]`}
              onClick={onLogout}
            >
              <div css={tw`flex items-center`}>
                <div
                  css={tw`h-11 w-11 rounded-[22px] items-center justify-center flex bg-[#F6F6F6]`}
                >
                  <CircleRightMajor width={20} height={20} />
                </div>
                <Typography fontWeight={600} css={tw`ml-4`}>
                  Log out
                </Typography>
              </div>
              <ChevronRightMinor fill="#B4AFC0" width={20} height={20} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const MobileMenu = memo(MobileMenuComponent)
