import { Formik } from 'formik'
import React, { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  RecollectionSetNewDocsUploaded,
  RecollectionUploadInsurance,
} from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { VehicleFormValues } from './InsuranceForm.interface'
import { VehicleSchema } from './InsuranceForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { FileUploadInput } from 'src/components/shared/FileUploadInput'
import { colors } from 'src/theme'
import { FormikSelect } from 'src/components/shared/form/formik/FormikSelect'
import { vehicleFormTypes, VehicleType } from 'src/constants/vehicle'
import { getRecollectionDriver } from 'src/selectors/driver'
import { UploadedFile } from 'src/components/shared/UploadedFile'
import { ShipmentMajor } from '@shopify/polaris-icons'
import { InfoContainer } from 'src/components/shared/InfoContainer'
import { CommonModal } from 'src/components/shared/CommonModal'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'

const initialValues: VehicleFormValues = {
  type: VehicleType.Sedan,
  carMake: '',
  carModel: '',
  doorsCount: '',
  year: '',
}

const USER_VEHICLE_TYPE = {
  sameVehicle: 'sameVehicle',
  newVehicle: 'newVehicle',
}

const YEAR_MASK = [/\d/, /\d/, /\d/, /\d/]
const DORS_MASK = [/\d/, /\d/]

const ACCESS_TYPES = ['image/jpeg', 'image/png', 'application/pdf']

export const InsuranceForm = () => {
  const dispatch = useDispatch()

  const driver = useSelector(getRecollectionDriver)
  const currentStep = useSelector(getCurrentRecollectionStep)

  const formikRef = useRef<any>()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [insuranceFile, setInsuranceFile] = useState<File | null>(null)
  const [userVehicleType, setUserVehicleType] = useState<string>(
    !driver?.carMake ? USER_VEHICLE_TYPE.newVehicle : USER_VEHICLE_TYPE.sameVehicle,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (values: VehicleFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(
        dispatch,
        RecollectionUploadInsurance.request({
          type:
            userVehicleType === USER_VEHICLE_TYPE.newVehicle
              ? values.type || driver?.type
              : driver?.type,
          carMake:
            userVehicleType === USER_VEHICLE_TYPE.newVehicle
              ? values.carMake || driver?.carMake
              : driver?.carMake,
          carModel:
            userVehicleType === USER_VEHICLE_TYPE.newVehicle
              ? values.carModel || driver?.carModel
              : driver?.carModel,
          doorsCount:
            userVehicleType === USER_VEHICLE_TYPE.newVehicle
              ? values.doorsCount || driver?.doorsCount
              : driver?.doorsCount,
          year:
            userVehicleType === USER_VEHICLE_TYPE.newVehicle
              ? values.year || driver?.year
              : driver?.year,
          file: insuranceFile,
          stepId: currentStep?.id,
        }),
      )
      dispatch(RecollectionSetNewDocsUploaded.success(true))
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeUserVehicleType = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === USER_VEHICLE_TYPE.sameVehicle && formikRef.current) {
      formikRef.current.resetForm({ values: initialValues })
    }

    setUserVehicleType(event.target.value)
  }, [])

  const handleUploadInsurance = useCallback(async (file: File) => {
    setInsuranceFile(file)
  }, [])

  const handleRemoveFile = useCallback(() => {
    setInsuranceFile(null)
  }, [])

  const openModal = useCallback(() => {
    setShowModal(true)
  }, [])

  const closeModal = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      validationSchema={
        userVehicleType === USER_VEHICLE_TYPE.newVehicle ? VehicleSchema : undefined
      }
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit }) => (
        <div css={tw`h-full flex flex-col justify-between`}>
          <div>
            <div
              css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-white mb-4`}
            >
              <ShipmentMajor width={20} />
            </div>
            <Typography css={tw`my-2`} variant="h2">
              Vehicle and insurance
            </Typography>
            <Typography css={tw`mb-4 w-3/4`} color={colors.SHADES_GREY_400}>
              Input your vehicle details and upload your insurance card.
            </Typography>
            <div css={tw`mb-4`}>
              <InfoContainer onClick={openModal}>
                Tap to access additional information about the insurance document
              </InfoContainer>
            </div>
            {!!driver?.type && (
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={userVehicleType}
                  onChange={handleChangeUserVehicleType}
                >
                  <FormControlLabel
                    value={USER_VEHICLE_TYPE.sameVehicle}
                    control={<Radio />}
                    label="I'm using the same vehicle as before"
                  />
                  <FormControlLabel
                    value={USER_VEHICLE_TYPE.newVehicle}
                    control={<Radio />}
                    label="I'm using new vehicle"
                  />
                </RadioGroup>
              </FormControl>
            )}
            {userVehicleType === USER_VEHICLE_TYPE.newVehicle && (
              <div css={tw`mt-4`}>
                <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
                  Enter vehicle details
                </Typography>
                <div css={tw`bg-white p-4 rounded-xl mt-2`}>
                  <div css={tw`w-full mb-4`}>
                    <FormikSelect
                      name="type"
                      label="Vehicle type"
                      placeholder="Select vehicle type"
                      options={vehicleFormTypes}
                    />
                  </div>
                  <FormikTextField
                    name="year"
                    css={tw`w-full mb-4`}
                    label="Vehicle year"
                    mask={YEAR_MASK}
                    placeholder="Enter year of production"
                  />
                  <FormikTextField
                    name="carMake"
                    css={tw`w-full mb-4`}
                    label="Make"
                    placeholder="Enter vehicle make E.g. Toyota"
                  />
                  <FormikTextField
                    name="carModel"
                    css={tw`w-full mb-4`}
                    label="Model"
                    placeholder="Enter vehicle model E.g. Tacoma XL"
                  />
                  <FormikTextField
                    name="doorsCount"
                    css={tw`w-full`}
                    label="Number of doors"
                    mask={DORS_MASK}
                    placeholder="Enter number of doors"
                  />
                </div>
              </div>
            )}
            <div css={tw`mt-6`}>
              {userVehicleType === USER_VEHICLE_TYPE.newVehicle && (
                <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
                  Upload insurance ID card
                </Typography>
              )}
              <div css={tw`bg-white p-4 rounded-xl mt-2`}>
                {insuranceFile ? (
                  <UploadedFile file={insuranceFile} onRemove={handleRemoveFile} />
                ) : (
                  <FileUploadInput
                    // 10 MB
                    fileSizeLimit={10000000}
                    acceptTypes={ACCESS_TYPES}
                    onSelect={handleUploadInsurance}
                  />
                )}
              </div>
            </div>
          </div>
          <FormErrorMessage css={tw`mt-4`} error={error} />
          <LoadingButton
            css={tw`w-full mt-6`}
            variant="contained"
            loading={isLoading}
            disabled={!insuranceFile}
            onClick={() => handleSubmit()}
          >
            <span>Submit and continue</span>
          </LoadingButton>
          <CommonModal
            isOpen={showModal}
            image={require('../../../assets/images/vehicle-insurance-example.png')}
            onClose={closeModal}
          />
        </div>
      )}
    </Formik>
  )
}
